import React from "react"
import {
  Heading,
  Button,
  Link as ChakraLink,
  Box,
  Flex,
  Text,
  Spacer,
  Tag,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react"
import { Link } from "../../Link"
import { useSelector } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import { insertStyledHTMLContent } from "../../../tools/helper"

const ProjektzielHeader = ({
  projektName,
  backButton,
  title,
  schwerpunkt,
  summary,
  projektbeteiligteName,
  projektbeteiligte,
  leitbegriffeName,
  leitbegriffe,
  leitbegriffeBeschreibung,
  leitbegriffeExtendedBeschreibung,
  projektReferenz,
  forschungsbeteiligte,
  linkTextLeitbegriffe,
  linkUrlLeitbegriffe,
  summaryName,
}) => {
  const CURRENT_LOCALE = useSelector(state => state.currentLocaleID)

  const query = useStaticQuery(graphql`
    query ForschungsHeaderQuery {
      de_DE: wpModeSettings(
        locale: { id: { eq: "de_DE" } }
        slug: { eq: "forschung" }
      ) {
        id
        acf_research_settings {
          forschungsbegriffe {
            institution
            disziplin
            name
          }
        }
      }
      en_US: wpModeSettings(
        locale: { id: { eq: "en_US" } }
        slug: { eq: "forschung" }
      ) {
        id
        acf_research_settings {
          forschungsbegriffe {
            institution
            disziplin
            name
          }
        }
      }
    }
  `)

  let settings = query[CURRENT_LOCALE]?.acf_research_settings
  const CURRENT_LANG = useSelector(state => state.currentLang)
  return (
    <Box marginBottom="0px">
      {(forschungsbeteiligte !== undefined || backButton) && (
        <ChakraLink
          display="inline-block"
          _hover={{ textDecoration: "none" }}
          onClick={() => {
            if (typeof window !== `undefined`) {
              window.history.back()
            }
          }}
        >
          <Text className="backLink" mb="24px">
            {CURRENT_LANG === "en" ? "Back" : "Zurück"}
          </Text>
        </ChakraLink>
      )}
      <Box width={["100%", "100%", "100%", "100%", "60%"]} maxWidth="640px">
        <Heading as="h1" size="md" color="modeGray.500" mb="8px">
          {schwerpunkt}
        </Heading>
        <Heading
          as="h2"
          fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
          mb={{ base: "24px", lg: "32px" }}
          maxWidth="920px"
          lineHeight={{ base: "1.4", md: "1.2" }}
        >
          {title}
        </Heading>
      </Box>
      <Flex
        direction={["column", "column", "column", "column", "row"]}
        mb={summary ? "40px" : "0px"}
      >
        {summary ? (
          <Box width={["100%", "100%", "100%", "100%", "60%"]} maxWidth="640px">
            <Heading
              as="h1"
              display="flex"
              alignItems="center"
              size="md"
              color="modeGray.500"
              mb="8px"
            >
              {projektName || summaryName}
            </Heading>
            <Text fontWeight="medium" fontSize="lg" className="WYSIWYG">
              {summary}
            </Text>
          </Box>
        ) : (
          ""
        )}
        <Spacer />
        <Flex
          direction="column"
          width={["100%", "100%", "100%", "100%", "40%"]}
          paddingLeft={["0px", "0px", "0px", "0px", "60px"]}
          maxWidth="640px"
        >
          {projektbeteiligte ? (
            <Box
              mt={
                projektReferenz?.name
                  ? ["24px", "24px", "24px", "24px", "-3px"]
                  : "0px"
              }
            >
              <Heading
                display="flex"
                alignItems="center"
                as="h1"
                size="md"
                color="modeGray.500"
                mb="8px"
              >
                {projektbeteiligteName || "Projektbeteiligte"}
                <Tag
                  size={"lg"}
                  marginLeft="16px"
                  variant="solid"
                  colorScheme="modeGray"
                  border="1px solid rgba(0,0,0,.2)"
                  color="black"
                  bgColor="white"
                >
                  {CURRENT_LOCALE === "de_DE"
                    ? projektReferenz?.name
                    : projektReferenz?.tax_translation?.enTranslation}
                </Tag>
              </Heading>
              <Box
                mt={projektReferenz?.name ? "-4px" : "0px"}
                pb={projektbeteiligte ? "32px" : "0px"}
                dangerouslySetInnerHTML={insertStyledHTMLContent(
                  projektbeteiligte
                )}
              ></Box>
            </Box>
          ) : (
            ""
          )}

          {forschungsbeteiligte ? (
            <Box mt={["24px", "24px", "24px", "24px", "0px"]}>
              <Heading
                display="flex"
                alignItems="center"
                as="h1"
                size="md"
                color="modeGray.500"
                mb="8px"
              >
                {projektbeteiligteName || "Projektbeteiligte"}
              </Heading>

              <Box mt={["0px", "0px", "0px", "0px", "-2px"]}>
                <Text>
                  <b>{settings.forschungsbegriffe.name}: </b>
                  {forschungsbeteiligte.textAutoren.length > 0
                    ? forschungsbeteiligte.textAutoren.map((i, k) => {
                        if (k !== forschungsbeteiligte.textAutoren.length - 1) {
                          return `${i.name}, `
                        } else {
                          return `${i.name}`
                        }
                      })
                    : ""}
                </Text>
              </Box>

              {forschungsbeteiligte?.disziplin ? (
                <Box>
                  <Text>
                    <b>{settings.forschungsbegriffe.disziplin}: </b>
                    {forschungsbeteiligte?.disziplin?.map(
                      (disziplin, index) => {
                        return CURRENT_LOCALE === "de_DE"
                          ? `${disziplin.name}${
                              index ===
                              forschungsbeteiligte?.disziplin?.length - 1
                                ? ""
                                : ", "
                            }` || ""
                          : `${disziplin?.tax_translation.enTranslation}${
                              index ===
                              forschungsbeteiligte?.disziplin?.length - 1
                                ? ""
                                : ", "
                            }` || ""
                      }
                    )}
                  </Text>
                </Box>
              ) : (
                ""
              )}

              <Box>
                <Text>
                  <b>{settings.forschungsbegriffe.institution}: </b>
                  {forschungsbeteiligte?.textInstitution?.map(
                    (institution, index) => {
                      return CURRENT_LOCALE === "de_DE"
                        ? `${institution.name}${
                            index ===
                            forschungsbeteiligte?.textInstitution?.length - 1
                              ? ""
                              : ", "
                          }` || ""
                        : `${institution?.acf_institute.enTranslation.name}${
                            index ===
                            forschungsbeteiligte?.textInstitution?.length - 1
                              ? ""
                              : ", "
                          }` || ""
                    }
                  )}
                </Text>
              </Box>
            </Box>
          ) : (
            ""
          )}

          {leitbegriffe ? (
            <Box>
              <Heading
                display="flex"
                as="h1"
                alignItems="center"
                size="md"
                color="modeGray.500"
                mb="12px"
              >
                {leitbegriffeName || "Leitbegriffe"}{" "}
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Button
                      borderRadius="full"
                      padding="0px"
                      width="24px"
                      minWidth="24px"
                      height="24px"
                      ml="8px"
                      fontWeight="bold"
                      color="buttonTertiary.700"
                      cursor="help"
                    >
                      ?
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    padding="24px"
                    border="none"
                    rounded="lg"
                    pl="27px"
                    className="elevation_lg"
                  >
                    <Heading color="black" size="md" mb="16px">
                      {leitbegriffeName}
                    </Heading>
                    <Text
                      color="black"
                      lineHeight="1.5em"
                      fontWeight="normal"
                      fontSize="sm"
                    >
                      {leitbegriffeBeschreibung}
                      {leitbegriffeExtendedBeschreibung}
                    </Text>
                    <Text
                      color="black"
                      pt="16px"
                      fontSize="md"
                      fontWeight="bold"
                      textAlign="right"
                      noOfLines={1}
                    >
                      <Link
                        className="linkSpanArrow hover:underline"
                        alt={linkTextLeitbegriffe}
                        to={linkUrlLeitbegriffe}
                        draggable={false}
                        style={{ userSelect: "none" }}
                        mousedownclick="true"
                      >
                        {linkTextLeitbegriffe}
                        <span>→</span>
                      </Link>
                    </Text>
                  </PopoverContent>
                </Popover>
              </Heading>
              {leitbegriffe?.map((leitbegriff, index) => (
                <Popover trigger="hover" key={leitbegriff.id}>
                  <PopoverTrigger>
                    <Tag
                      key={index}
                      size={"lg"}
                      marginRight="8px"
                      variant="solid"
                      cursor="help"
                      rounded="md"
                      border="1px solid rgba(0,0,0,.2)"
                      color="black"
                      bgColor="white"
                      mb="1"
                    >
                      <Flex>
                        <Box h="24px" lineHeight="24px">
                          {CURRENT_LOCALE === "de_DE"
                            ? leitbegriff.name
                            : leitbegriff.keyword_translation?.enTranslation
                                ?.name}
                        </Box>
                        <Box
                          ml="8px"
                          bg="gray.100"
                          padding="0"
                          rounded="full"
                          minW="24px"
                          h="24px"
                          pt="2px"
                          fontWeight="bold"
                          color="buttonTertiary.700"
                          cursor="help"
                          textAlign="center"
                        >
                          ?
                        </Box>
                      </Flex>
                    </Tag>
                  </PopoverTrigger>
                  <PopoverContent
                    padding="24px"
                    border="none"
                    rounded="lg"
                    className="elevation_lg"
                  >
                    <Heading color="black" size="md" mb="16px">
                      {CURRENT_LOCALE === "de_DE"
                        ? leitbegriff.name
                        : leitbegriff.keyword_translation?.enTranslation?.name}
                    </Heading>
                    <Text color="black" fontWeight="normal" fontSize="sm">
                      {CURRENT_LOCALE === "de_DE"
                        ? leitbegriff.description
                        : leitbegriff.keyword_translation?.enTranslation
                            ?.beschreibung}
                    </Text>
                  </PopoverContent>
                </Popover>
              ))}
            </Box>
          ) : (
            ""
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default ProjektzielHeader
