import React from "react"
import { Box, Flex, Spacer } from "@chakra-ui/react"

const SinglePageWrapper = props => {
  return (
    <Flex
      pt={{ base: "80px", md: "120px" }}
      height="100%"
      minHeight="100vh"
      direction={["column", "column", "row"]}
      justify="flex-end"
      align="flex-start"
      background={props.background}
      overflowX="hidden"
    >
      {/* ... Pagination Spacing */}
      <Box display={["none", "none", "none", "block"]} w="60px"></Box>
      {/* ContentArea */}
      <Spacer display={["none", "none", "none", "block"]} />
      <Flex
        w={["100%", "100%", "100%", "85%"]}
        align="flex-end"
        justify="flex-end"
        // padding="16px"
        pr="0px"
        direction="column"
      >
        <Flex
          p={["0px 32px", "0 32px", "0 48px", "64px"]}
          width="100%"
          position="relative"
          borderLeftRadius="30px"
          align="center"
          justify="center"
          maxWidth="1920px"
        >
          <Box maxWidth="1300px" width="100%">
            {props.children}
          </Box>
        </Flex>
      </Flex>
      <Spacer />
    </Flex>
  )
}

export default SinglePageWrapper
